<template>
  <!-- prettier-ignore -->
  <index-content :breadcrumb-items="[$t(`menu.customer.customer_message_template`)]">
    <template v-if="hasRW" #options>
      <div class="n-button-box">
        <nb-add @on-click="openModal" />
      </div>
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-3">{{ $t('label.type') }}</th>
            <th class="col-weight-2">{{ $t('label.language') }}</th>
            <th class="col-weight-2">{{ $t('label.variant') }}</th>
            <th class="col-weight-4">{{ $t('label.subject') }}</th>
            <th class="col-weight-8">{{ $t('label.content') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-enums:CustomerMessageType="row.messageType"></td>
            <td v-enums:Language="row.language"></td>
            <td>{{ row.messageVariant }}</td>
            <td style="max-width:210px;" class="n-ellipsis" :title="row.messageSubject">{{ row.messageSubject }}</td>
            <td style="max-width:410px;" class="n-ellipsis" :title="row.messageContent">{{ row.messageContent }}</td>
            <td class="n-button-box">
              <nb-custom v-rw type="error" custom-icon="fas fa-clone" text="copy" @on-click="openCopy(row)" />
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import CustomerView from './view';
import { beanCopy } from '@/helpers/utils';
import ModalCustomerMessageTemplate from './ModalCustomerMessageTemplate';
import { fetch } from '@/api/customer/customer-customer-message-template';

export default CustomerView.extend({
  name: 'CustomerCustomerMessageTemplate',
  data() {
    return {
      preload: true,
      condition: {
        companyId: null,
      },
    };
  },
  methods: {
    doLoad() {
      let params = { companyId: this.condition.companyId };
      return fetch(params);
    },
    parse(rows) {
      this.records = rows;
    },
    openModal(model = {}) {
      this.createModal(ModalCustomerMessageTemplate, { model, on: this });
    },

    openCopy(o) {
      let clone = beanCopy(o);
      clone.id = undefined;
      clone.version = undefined;
      this.createModal(ModalCustomerMessageTemplate, { model: clone, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 800px;
  th.rw-action {
    width: 8.5rem;
  }
}
</style>
