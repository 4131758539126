<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :sub-name="$t('template')">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="isEdit || disabled" />
        <n-field-enum v-model="model.messageType" field-name="messageType" enum-name="CustomerMessageType" label="type" :error="error.messageType" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.language" field-name="language" enum-name="Language" label="language" :error="error.language" :disabled="isEdit || disabled" />
        <n-field-text v-model="model.messageVariant" field-name="messageVariant" label="variant" :error="error.messageVariant" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-id v-model="model.version" label="version" disabled />
        <n-field-enum v-model="model.status" field-name="status" enum-name="CustomerPushStatus" label="status" :error="error.status" :disabled="disabled" />
      </n-row-col2>
      <n-row-col1>
        <n-field-text v-model="model.messageImage" field-name="messageImage" label="image" :error="error.messageImage" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-field-text v-model="model.messageSubject" field-name="messageSubject" label="subject" :error="error.messageSubject" :disabled="disabled" />
      </n-row-col1>
      <n-row-col1>
        <n-text-editor v-model="model.messageContent" :html="model.wysiwyg" field-name="messageContent" label="content" :error="error.messageContent" :disabled="disabled" />
      </n-row-col1>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { create, update, validate } from '@/api/customer/customer-customer-message-template';

export default NModal.extend({
  name: 'ModalCustomerMessageTemplate',
  components: { NModal },
  methods: {
    doValidate(model) {
      return validate(model);
    },
    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },
  },
});
</script>
